<template>
  <b-card
    class="warehousehead-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehouseheadForm" class="edit-form mt-2">
      <b-row>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="warehousehead.warehouse_id"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="发票抬头"
            label-for="head_info"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="head_info"
              size="sm"
              v-model="warehousehead.head_info"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否默认"
            label-for="is_default"
            label-size="sm"
            class="mb-1 required"

          >
              <v-select
                  id="is_default"
                  :options="getCodeOptions('yesno')"
                  :clearable="true"
                  v-model ="warehousehead.is_default"
                  class="select-size-sm"
                  placeholder="是否默认"
                  :reduce="option => option.value"
              />

          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1 required"
          >
            <v-select
                id="state"
                :options="getCodeOptions('state')"
                :clearable="true"
                v-model = "warehousehead.state"
                class="select-size-sm"
                placeholder="选择状态"
                :reduce="option => option.value"
            />

          </b-form-group>
        </b-col>




                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehouseheadStore from './warehouseheadStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      warehousehead: ref({}),
      warehouse_id:0,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehousehead')) store.registerModule('warehousehead', warehouseheadStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousehead')) store.unregisterModule('warehousehead')
    })

    const edit = function() {
      store.dispatch('warehousehead/edit', {id: this.id}).then(res => {
        this.warehousehead = res.data.data
        if(this.warehouse_id>0){
          this.warehousehead.warehouse_id = this.warehouse_id
        }
        if(this.id>0){
          this.warehouse_id =  this.warehousehead.warehouse_id
          this.warehousehead.is_default = getCodeLabel('yesno',this.warehousehead.is_default)
          this.warehousehead.state = getCodeLabel('state',this.warehousehead.state)
        }
      })
    }

    const view = function() {
      store.dispatch('warehousehead/view', {id: this.id}).then(res => {
        this.warehousehead = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      if(this.warehousehead.state==undefined||this.warehousehead.is_default==undefined||this.warehousehead.head_info==undefined){
        toast.error('请填写数据!')
        return
      }
      store.dispatch('warehousehead/save', this.warehousehead).then(res => {
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-warehousehead-list',query:{id:this.warehouse_id}});
        } else {
          toast.error(res.data.data)
          this.$router.push({ name: 'apps-warehousehead-list',query:{id:this.warehouse_id}});
        }
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.warehouse_id = this.$route.query.warehouse_id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
