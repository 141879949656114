import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehousehead/search', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehousehead/view', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/warehousehead/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehousehead/save', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehousehead/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteWarehouseHead(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehousehead/deleteWarehouseHead', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getByWarehouseId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/warehousehead/getByWarehouseId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
